const colors = {
  blue: '#27b0d4',
};

const breakpoints = {
  sm: 512, // 32em
  md: 768, // 48em
  lg: 1024, // 64em
  xl: 1440, // 90em
  xsMax: 511,
  smMax: 767,
  mdMax: 1023,
  lgMax: 1439,
};

const fixedTagCategoryOrder = {
  Altersstufe: 0,
  Leseniveau: 1,
  Unterrichtsfach: 2,
  Fremdsprache: 3,
  Genre: 4,
  Themen: 5,
  Kompetenzen: 6,
  Funktionen: 7,
};

const organizationUserRoles = {
  OWNER: 'OWNER',
  RENTAL_USER: 'RENTAL_USER',
  USER: 'USER',
};

const contentPagesWithOwnSlug = ['didaktik', 'themenwelten', 'blog'];
const isProduction = document.querySelector("meta[name='node-env']").getAttribute('content') === 'production';
const wpApiUrl = document.querySelector("meta[name='react-app-wp-api-url']").getAttribute('content') || '/wp/wp-json';

const saml2Login = {
  path: document.querySelector("meta[name='react-app-saml2-path']").getAttribute('content'),

  text: document.querySelector("meta[name='react-app-saml2-text']").getAttribute('content'),
};

const vidisLogin = {
  url: document.querySelector("meta[name='react-app-vidis-path']").getAttribute('content'),
};

export {
  colors,
  breakpoints,
  fixedTagCategoryOrder,
  contentPagesWithOwnSlug,
  isProduction,
  wpApiUrl,
  saml2Login,
  organizationUserRoles,
  vidisLogin,
};
